<template>
  <div class="font-light border-cyan-50 border-b-2">RAPORT</div>
  <!-- report blocks -->
  <div class="flex items-center justify-center">
    <div class="rep-block">
      <div class="rep-block-title bg-sky-700 text-white">
        <h3>SHITJE</h3>
      </div>
      <div class="rep-block-content flex items-center justify-center ">
        <p class="font-bold ">10,000</p>
      </div>
    </div>

    <div class="rep-block">
      <div class="rep-block-title bg-cyan-700 text-white">
        <h3>BLERJE</h3>
      </div>
      <div class="rep-block-content flex items-center justify-center">
        <p class="font-bold">12,000</p>
      </div>
    </div>

    <div class="rep-block">
      <div class="rep-block-title bg-green-700 text-white">
        <h3>PROFIT</h3>
      </div>
      <div class="rep-block-content flex items-center justify-center">
        <p class="font-bold">2,000</p>
      </div>
    </div>
  </div>
  <!-- end report blocks -->
  <!-- articles: [Ne limit, Sapo Ardhura, Me shume te shitura] report blocks -->
  <div class="font-light border-blue-50 border-b-2">ARTIKUJ</div>
  <div class="articles-report-blocks flex justify-center items-start">
    
    <div class="rep-block">
      <div class="rep-block-title bg-sky-700 text-white">
        <h3>TE SAPO ARDHURA</h3>
      </div>
      <div class="rep-block-content flex items-center">
        <!-- list of articles -->
        <ul class="w-full">
          <li class="flex justify-between" v-for="article in useDashboardStore().new_articles" 
            :key="article.guid">
              <span>{{ article.name }}</span>
              <span>{{ article.total_quantity }}</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="rep-block">
      <div class="rep-block-title bg-red-700 text-white">
        <h3>NE LIMIT</h3>
      </div>
      <div class="rep-block-content flex items-center">
        <!-- list of articles -->
        <ul class="w-full">
          <li class="flex justify-between" v-for="article in useDashboardStore().stock_limit_articles" 
            :key="article.guid">
              <span>{{ article.name }}</span>
              <span>{{ article.total_quantity }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="rep-block"> 
      <div class="rep-block-title bg-green-700 text-white">
        <h3>ME TE SHITUR</h3>
      </div>
      <div class="rep-block-content flex items-center justify-center">
        <!-- list of articles -->
        <ul class="w-full">
          <li class="flex justify-between" v-for="article in useDashboardStore().most_sold_articles" 
            :key="article.guid">
              <span>{{ article.name }}</span>
              <span>{{ Math.abs(article.total_quantity) }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useDashboardStore } from '@/stores/dashboard'

onMounted(() => { useDashboardStore().getReports() })
</script>

<style scoped>
.rep-block {
  min-width: 200px;
  background-color: #fff;
  border: 1px solid #dedede;
  margin: 10px;
}

.rep-block-title {
  width: 100%;
  height: 30px;
  border-bottom: 1px solid #dedede;
  padding: 5px;
}

.rep-block-content {
  width: 100%;
  padding: 5px;
}

h3 { font-size: 12px; }

li {
  border-bottom: 1px solid #dedede;
  padding: 5px;
  font-size: 12px;
}

li:last-child { border-bottom: none; }

.articles-report-blocks > * { min-width: 350px !important; }
.articles-report-blocks li span:nth-child(2) { font-weight: bold; }
.articles-report-blocks li span:nth-child(1) {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

