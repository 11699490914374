// dashboard pinia store 
import { defineStore } from 'pinia'
import Api from "@/services/Api";


export const useDashboardStore = defineStore('dashboard', {
  state: () => ({
    stock_limit_articles: [],
    new_articles: [],
    most_sold_articles: [],
  }), 
  getters: {
  },
  actions: {
    async getReports() {
      const response = await Api(true).get('/dashboard/reports');
      this.stock_limit_articles = response.data.stock_limit_articles;
      this.new_articles = response.data.new_articles;
      this.most_sold_articles = response.data.most_sold_articles;
      return response.data;
    },
  }
})
      
